<template>
  <PopUp :show="modal" @hideModal="hideModal">
    <ValidationObserver v-slot="{ invalid }" tag="div" ref="observer" class="editorial">
      <header class="editorial__header">
        <h3 class="editorial__header_title">{{ editorial.id ? "Edit" : "Add" }} Publication</h3>
        <div class="editorial__header_actions">
          <BaseButton
            v-if="item"
            class="button--sm button--outline button--uppercase"
            text="Delete Publication"
            @click="$emit('removeEditorial', editorial)"
          ></BaseButton>
          <BaseButton
            class="button--sm button--green button--uppercase"
            text="Save Changes"
            :loading="changesLoading"
            :disabled="invalid"
            @click="saveEditorial"
          ></BaseButton>
        </div>
      </header>
      <section class="form">
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Article Title"
              :required="true"
              :validateError="errors[0]"
              v-model="editorial.title"
              :element="editorial.title"
              @input="editorial.title = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Author"
              :required="true"
              :validateError="errors[0]"
              v-model="editorial.author"
              :element="editorial.author"
              @input="editorial.author = $event"
            ></BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true }"
            v-slot="{ errors }"
          >
            <DatePicker
              placeholder="Publish Date"
              :required="true"
              :validateError="errors[0]"
              :value="editorial.publish_date"
              v-model="editorial.publish_date"
              @input="editorial.publish_date = $event"
            ></DatePicker>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, path: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Path"
              :required="true"
              :validateError="errors[0] || serverError.slug"
              v-model="editorial.slug"
              :element="editorial.slug"
              @input="editorial.slug = $event"
            >
              <template #text>
                <span class="form-control__simple-text">
                  Example: {{ `${url}/library/publications/${editorial.slug || "path"}` }}
                </span>
              </template>
            </BaseInput>
          </ValidationProvider>
          <div class="form__row_col form__row_col--side">
            <BaseSelect
              placeholder="Status"
              v-model="editorial.is_published"
              type="status"
              :selected="editorial.is_published"
              :items="[
                { key: '1', title: 'Published' },
                { key: '0', title: 'Draft' },
              ]"
              @action="editorial.is_published = $event.key"
            ></BaseSelect>
          </div>
        </div>
        <div class="form__row">
          <div class="form__row_col">
            <BaseSelect
              placeholder="Type"
              v-model="editorial.type"
              :selected="editorial.type"
              :items="editorialTypes"
              @action="editorial.type = $event.key"
            ></BaseSelect>
          </div>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, max: 1000 }"
            v-slot="{ errors }"
          >
            <BaseTextarea
              placeholder="Description"
              :required="true"
              :validateError="errors[0]"
              v-model="editorial.description"
              :element="editorial.description"
              @input="editorial.description = $event"
            ></BaseTextarea>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Button Text"
              :validateError="errors[0]"
              v-model="editorial.button_text"
              :element="editorial.button_text"
              @input="editorial.button_text = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Link"
              :validateError="errors[0]"
              v-model="editorial.button_url"
              :element="editorial.button_url"
              @input="editorial.button_url = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
      </section>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";
import BaseTextarea from "@/components/inputs/BaseTextarea.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  props: { item: { type: Object, default: null }, modal: { type: Boolean, default: false } },
  components: {
    PopUp,
    ValidationObserver,
    ValidationProvider,
    BaseButton,
    BaseInput,
    BaseSelect,
    BaseTextarea,
    DatePicker,
  },
  data() {
    return {
      url: process.env.VUE_APP_FRONTEND_URL,
      editorial: {
        title: "",
        author: "",
        slug: "",
        is_published: null,
        publish_date: null,
        description: "",
        button_text: "",
        button_url: "",
        type: null,
      },
      changed: false,
    };
  },
  created() {
    if (this.item != null) {
      this.editorial = JSON.parse(JSON.stringify(this.item));
    }
  },
  beforeDestroy() {
    this.setError(null);
  },
  computed: {
    ...mapGetters("editorials", {
      serverError: "getServerError",
      changesLoading: "getChangesLoading",
      editorialTypes: "getEditorialTypes",
    }),
  },
  watch: {
    editorial: {
      handler: function (val, oldVal) {
        if (this.item != null) {
          if (oldVal.is_published != null) {
            this.changed = true;
          }
        } else {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("editorials", ["setError"]),
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$refs.observer.flags.valid ? this.saveEditorial() : this.showToast();
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
    showToast() {
      this.$toasted.show(`Please fill in all required fields`, {
        duration: 2000,
        type: "error",
      });
    },
    saveEditorial() {
      if (this.editorial.id) {
        this.$emit("updateEditorial", this.editorial);
      } else {
        this.$emit("createEditorial", this.editorial);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.editorial {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start;
    }
    &_title {
      font-size: 3.35rem;
      font-weight: 600;
      line-height: 1.2;
    }
    &_actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: $xs) {
        justify-content: flex-start;
        width: 100%;
      }
      .button {
        margin-left: 10px;
        @media only screen and (max-width: $xs) {
          margin: 20px 10px 0 0;
        }
      }
    }
  }
}
</style>
