<template>
  <div class="page">
    <div class="page__header">
      <HorisontalTabsList :tabs="tabs"></HorisontalTabsList>
      <div class="page__header_right">
        <FilterBlock
          :items="filters"
          @open="openFilters = $event"
          @selectFilter="selectFilter"
          @apply="refreshSelectedFilters"
          @clear="refreshSelectedFilters(true)"
        ></FilterBlock>
        <IconButton
          class="button--auto button--uppercase"
          text="add publication"
          @click="modal = true"
        >
          <IconPlusBold></IconPlusBold>
        </IconButton>
      </div>
    </div>
    <div class="page__filters">
      <template v-for="filter in selectedFilters">
        <Tag
          class="tag--capitalize"
          v-for="i in filter.items"
          :key="i.title"
          :value="i.title"
          :close="true"
          :disabled="openFilters"
          @deleteTag="selectFilter({ key: filter.key, filter: i, apply: true })"
        ></Tag>
      </template>
    </div>
    <Table
      :loading="loading"
      :items="items"
      :headerItems="headerItems"
      :tableData="tableData"
      :hasMorePages="hasMorePages"
      @sortTable="sortTable"
      @clickAction="clickAction"
      @loadMore="loadMore"
    ></Table>
    <Editorial
      v-if="modal"
      :modal="modal"
      :item="editorial"
      @hideModal="modal = false"
      @createEditorial="createEditorial"
      @updateEditorial="updateEditorial"
      @removeEditorial="confirmRemoveEditorial"
    ></Editorial>
    <OrderModal
      v-if="orderModal"
      :modal="orderModal"
      :items="items"
      :loading="loading"
      @reorder="reorderItems"
      @hideModal="orderModal = false"
    ></OrderModal>
  </div>
</template>
<script>
import HorisontalTabsList from "@/components/HorisontalTabsList.vue";
import FilterBlock from "@/components/Filter.vue";
import Tag from "@/components/Tag.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconPlusBold from "@/components/icons/IconPlusBold.vue";
import Table from "@/components/Table.vue";
import Editorial from "./Editorial.vue";
import OrderModal from "./OrderModal.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
export default {
  components: {
    HorisontalTabsList,
    FilterBlock,
    IconButton,
    IconPlusBold,
    Table,
    Tag,
    Editorial,
    OrderModal,
  },
  data() {
    return {
      tabs: [
        {
          title: "Blogs",
          path: "/admin/content/blogs",
          key: "blog",
        },
        {
          title: "Publications",
          path: "/admin/content/publications",
          key: "editorial",
        },
        {
          title: "Tag Manager",
          path: "/admin/content/tag-manager",
          key: "tags",
        },
        {
          title: "Pages",
          path: "/admin/content/pages",
          key: "pages",
        },
      ],
      modal: false,
      filterBlock: {
        list: "statuses",
        type: "content",
      },
      openFilters: false,
      tableData: {
        page: 1,
        sort: "title",
        order: "asc",
        status: "",
      },
      orderModal: false,
      // orderType: null,
    };
  },
  async created() {
    await this.apiGetEditorialTypes();
    await this.apiGetFiltersBlock(this.filterBlock);
    await this.apiGetEditorials(this.tableData);
    this.initialEditorial();
  },
  computed: {
    ...mapGetters("filters", {
      filters: "getFilters",
      selectedFilters: "getSelectedFilters",
    }),
    ...mapGetters("editorials", {
      items: "getEditorials",
      headerItems: "getEditorialsHeaders",
      editorial: "getEditorial",
      loading: "getLoading",
      hasMorePages: "getHasMorePages",
    }),
  },
  watch: {
    modal(val) {
      if (!val) {
        this.initialEditorial();
        this.setError(null);
      }
    },
  },
  methods: {
    ...mapActions("filters", ["apiGetFiltersBlock"]),
    ...mapMutations("filters", ["updateFilters", "updateSelectedFilters", "clear"]),
    ...mapActions("editorials", [
      "apiGetEditorials",
      "apiGetEditorial",
      "apiPostEditorial",
      "apiPutEditorial",
      "apiDeleteEditorial",
      "apiPutChoseOrder",
      "apiGetEditorialTypes",
    ]),
    ...mapMutations("editorials", ["initialEditorial", "setError"]),
    selectFilter(val) {
      this.updateFilters(val);
      if (val.apply) {
        this.refreshSelectedFilters();
      }
    },
    async sortTable({ sort, order }) {
      let data = { ...this.tableData, page: 1, sort, order };
      let success = await this.apiGetEditorials(data);
      if (success) {
        this.tableData = data;
      }
    },
    async loadMore() {
      if (this.hasMorePages) {
        let data = { ...this.tableData, page: this.tableData.page + 1 };
        let success = await this.apiGetEditorials(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    async refreshSelectedFilters(clear) {
      if (clear) {
        await this.clear();
      }
      await this.updateSelectedFilters();
      this.filterTable();
    },
    async filterTable() {
      let data = { ...this.tableData, page: 1 };
      this.selectedFilters.forEach(el => {
        let filters = el.items.map(i => {
          return i.id;
        });
        data[el.key] = filters.join(",");
      });
      let success = await this.apiGetEditorials(data);
      if (success) {
        this.tableData = data;
      }
    },
    async clickAction({ key, id, item }) {
      switch (key) {
        case "edit": {
          let result = await this.apiGetEditorial(id);
          if (result) {
            this.modal = true;
          }
          break;
        }
        case "delete": {
          let result = await this.confirmRemoveEditorial(item);
          if (result) {
            this.modal = true;
          }
          break;
        }
        case "reorder": {
          // this.orderType = item.orderType;
          this.orderModal = true;
          break;
        }
      }
    },

    async createEditorial(data) {
      let result = await this.apiPostEditorial(data);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetEditorials(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        this.$swal({
          title: "Editorial successfully created",
          confirmButtonText: "Ok",
        });
      }
    },
    async updateEditorial(data) {
      let result = await this.apiPutEditorial(data);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetEditorials(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        this.$swal({
          title: "Editorial successfully updated",
          confirmButtonText: "Ok",
        });
      }
    },
    confirmRemoveEditorial(item) {
      this.$swal({
        title: `Delete ${item.title}`,
        html: "<h5>Are you sure? <br/> This cannot be undone.",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: "Delete",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.value === false) {
          this.removeEditorial(item.id);
        }
      });
    },
    async removeEditorial(id) {
      let result = await this.apiDeleteEditorial(id);
      if (result.success) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetEditorials(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        this.$toasted.show(result.message, {
          duration: 2000,
          type: "error",
        });
      }
    },
    async reorderItems(items) {
      let data = { ...this.tableData, page: 1 };
      let result = await this.apiPutChoseOrder(items);
      if (result) {
        let success = await this.apiGetEditorials(data);
        if (success) {
          this.tableData = data;
        }
        this.orderModal = false;
        // this.orderType = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    &_right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .button {
        margin: 0 0 0 2rem;
      }
    }
  }
  &__filters {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 7px;
    .tag {
      margin-left: 1rem;
    }
  }
}
</style>
