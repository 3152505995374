<template>
  <svg :width="width" :height="height" :viewBox="viewBox" fill="none">
    <g :fill="color">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.818362 1.50705e-06H18.1815C18.4879 -0.000593593 18.7687 0.175096 18.9087 0.454889C19.0505 0.738443 19.0256 1.07971 18.8444 1.33848L12.4834 10.5412L12.4768 10.5506C12.246 10.871 12.1209 11.2592 12.1202 11.658V17.75C12.1202 18.1238 11.9076 18.4341 11.5873 18.5344L8.01282 19.9342C7.91126 19.9774 7.80262 20 7.69251 20C7.4762 20 7.2688 19.9116 7.11634 19.7539C6.96375 19.5963 6.87879 19.3829 6.88013 19.1606V11.658C6.8794 11.2591 6.75452 10.871 6.52332 10.5506L6.51673 10.5412L0.155642 1.33888C-0.025632 1.0799 -0.0505343 0.738443 0.0913114 0.454889C0.231326 0.175096 0.512088 -0.000593583 0.818362 1.50705e-06V1.50705e-06ZM11.1312 11.658C11.1324 11.0429 11.3246 10.4443 11.6798 9.94913L17.8556 1.01526H1.14453L7.32007 9.94913C7.67554 10.4443 7.86768 11.0429 7.8689 11.658V18.904L11.1312 17.6266V11.658V11.658Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 19 20" },
    width: { type: String, default: "19" },
    height: { type: String, default: "20" },
    color: { type: String },
  },
};
</script>