<template>
  <div class="form-control form-control--icon">
    <div class="form-control_relative">
      <label
        class="form-control__label"
        :class="{ 'form-control__label--valid': date || focusDate }"
        >{{ placeholder }}
        <span class="form-control__label_required" v-if="required">*</span></label
      >
      <DatePicker
        class="collect-datepicker"
        format="MMM DD YYYY"
        name="date"
        v-model="date"
        value-type="YYYY-MM-DD"
        input-class="form-control__input"
        :disabled="disabled"
        @focus="focusDate = true"
        @blur="focusDate = false"
      ></DatePicker>
    </div>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
export default {
  props: {
    value: { type: String },
    placeholder: { type: String },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
  },
  components: { DatePicker },
  data() {
    return {
      date: null,
      focusDate: false,
    };
  },
  created() {
    this.date = this.value;
  },
  watch: {
    value(val) {
      this.date = val;
    },
    date(val) {
      this.$emit("input", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-control {
  position: relative;
  margin-top: 10px;
  &_relative {
    position: relative;
    height: 9rem;
    border: 0.5px solid $lightGrey;
    border-radius: 12px;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      height: 7rem;
    }
  }
  &__label {
    width: calc(100% - 5.1rem);
    position: absolute;
    @include type($fw: 500);
    color: $lightBlack;
    top: 50%;
    left: 2.4rem;
    transform: translateY(-50%);
    padding: 1px 1.1rem;
    background-color: $white;
    transition: all 0.1s ease;
    pointer-events: none;
    z-index: 1;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
    &_required {
      font-size: 18px;
      font-weight: 600;
      color: $error;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
    &--valid {
      top: 0;
      width: auto;
    }
  }
}
</style>
