<template>
  <button class="button" :class="{ 'button--active': value }" @click.prevent="$emit('click')">
    <span class="button__item"></span>
    <span class="button__item"></span>
  </button>
</template>
<script>
export default {
  props: { value: { type: Boolean, default: false } },
};
</script>
<style lang="scss" scoped>
.button {
  position: relative;
  height: 3.4rem;
  width: 3.4rem;
  border: 1px solid $lightGrey;
  border-radius: 5px;
  background-color: transparent;
  transition: all 0.3s ease;
  &__item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $black;
    width: 8px;
    height: 1px;
    transition: all 0.1s ease-in-out;
    &:last-of-type {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
  &:hover,
  &--active {
    background-color: $black;
    .button__item {
      background-color: $white;
    }
  }
  &--active {
    .button__item {
      &:last-of-type {
        transform: translate(-50%, -50%) rotate(0);
      }
    }
  }
}
</style>