<template>
  <div class="form-control">
    <div
      class="form-control__select"
      :class="{ 'form-control__select--placeholder': !element }"
      @click="visible = !visible"
    >
      <label
        class="form-control__select_label"
        :class="{ 'form-control__select_label--selected': element }"
        >{{ placeholder
        }}<span class="form-control__select_label_required" v-if="required">*</span></label
      >
      <span class="text" :class="{ 'text--status': type === 'status' }"
        ><span :class="`text--status_${element}`"></span>{{ element }}</span
      >
      <span class="icon" :class="{ 'icon--open': !disabled ? visible : false }">
        <IconArrowTop></IconArrowTop>
      </span>
    </div>
    <div
      class="form-control__items"
      :class="{ 'form-control__items--visible': !disabled ? visible : false }"
    >
      <div class="form-control__items_body">
        <ul class="list">
          <li v-for="i in items" :key="i.key">
            <a
              class="list__item"
              :class="{
                'list__item--active': element === i.title,
                'list__item--status': type === 'status',
              }"
              @click.prevent="
                $emit('action', {
                  key: i.key,
                  title: i.title,
                });
                visible = false;
              "
              ><span :class="`list__item--status_${i.title}`"></span>{{ i.title }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <p class="form-control__error">{{ validateError }}</p>
  </div>
</template>
<script>
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
export default {
  props: {
    type: { type: String, required: false },
    items: { type: Array },
    selected: { type: [String, Number], required: false },
    placeholder: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    validateError: { type: String, default: "" },
  },
  components: { IconArrowTop },
  data() {
    return {
      visible: false,
      element: "",
    };
  },
  created() {
    this.takeSelect();
  },
  watch: {
    selected() {
      this.takeSelect();
    },
  },
  methods: {
    takeSelect() {
      for (let el of this.items) {
        if (this.selected == el.key) {
          this.element = el.title;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-control {
  position: relative;
  margin-top: 10px;
  cursor: pointer;
  &__select {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 9rem;
    color: $lightBlack;
    background: #ffffff;
    border: 0.5px solid $lightGrey;
    border-radius: 12px;
    padding-left: 2.7rem;
    padding-right: 2.4rem;
    @include type();
    transition: all 0.3s ease;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      height: 7rem;
      font-size: 14px;
    }
    &_label {
      position: absolute;
      top: 50%;
      left: 2.4rem;
      transform: translateY(-50%);
      @include type($fw: 500);
      padding: 1px 1.1rem;
      background-color: $white;
      transition: all 0.1s ease;
      pointer-events: none;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
      &--selected {
        top: 0;
      }
      &_required {
        display: inline-block;
        font-size: 18px;
        font-weight: 600;
        color: $error;
        margin-left: 4px;
        @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
          font-size: 14px;
        }
      }
    }
    .text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 5px;
      color: $lightBlack;
      @include type();
      &--status {
        position: relative;
        display: inline-flex;
        align-items: center;
        &_Inactive,
        &_Active,
        &_Published,
        &_Draft {
          display: inline-block;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          margin-right: 12px;
        }
        &_Inactive {
          background-color: $light3Grey;
        }
        &_Draft {
          background-color: $orange;
        }
        &_Active,
        &_Published {
          background-color: $green;
        }
      }
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
  }
  &--disabled {
    opacity: 0.5;
    cursor: default;
  }
  &--invalid {
    .form-control__select {
      border: 1px solid $error;
      color: $error;
    }
  }
  .icon {
    display: inline-flex;
    width: 1.85rem;
    height: 1.85rem;
    transition: all 0.3s ease-in-out;
    transform: rotate(-180deg);
    &--open {
      transform: rotate(0);
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      width: 14px;
      height: 14px;
    }
  }
  &__items {
    width: calc(100% + 30px);
    position: absolute;
    left: -15px;
    bottom: 0;
    transform: translate(0, 100%);
    z-index: 3;
    padding: 10px 15px;
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    background-color: #fff;
    &--visible {
      visibility: visible;
      opacity: 1;
      max-height: 500px;
    }
    &_body {
      padding: 5px 0;
      border-radius: 12px;
      box-shadow: 0 2px 12px rgba($color: $black, $alpha: 0.25);
      .list {
        padding: 0px 5px;
        overflow: hidden;
        overflow-y: auto;
        max-height: 280px;
        &__item {
          display: block;
          padding: 12px 15px;
          @include type();
          background-color: #fff;
          cursor: pointer;
          border-radius: 2px;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover,
          &--active {
            background-color: $blueLight;
            color: $blue;
          }
          &--status {
            padding-left: 35px;
            &_Inactive,
            &_Active,
            &_Published,
            &_Draft {
              position: relative;
              &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: -25px;
                transform: translateY(-50%);
                display: inline-block;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                margin-right: 12px;
              }
            }
            &_Inactive:after {
              background-color: $light3Grey;
            }
            &_Draft:after {
              background-color: $orange;
            }
            &_Active,
            &_Published {
              &:after {
                background-color: $green;
              }
            }
          }
          @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
            font-size: 14px;
          }
          a {
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
          }
        }
      }
    }
  }
  &--grey {
    .form-control {
      &__select {
        background: transparent;
        &_label {
          background-color: #fbfaff;
        }
      }
      &__items {
        background-color: #fbfaff;
      }
    }
  }
  &--xs {
    min-width: 18rem;
    width: max-content;
    .form-control__select {
      height: 6rem;
      width: 100%;
      min-width: 16rem;
      border-radius: 8px;
      padding: 0 20px 0 10px;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        height: 5rem;
        font-size: 14px;
      }
      &_label {
        left: 10px;
      }
    }
  }
}
</style>

