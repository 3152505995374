<template>
  <div class="filter">
    <header class="filter__header">
      <p class="text">Filter by</p>
      <IconButton class="button--md" @click="open = true">
        <IconFilter />
      </IconButton>
    </header>
    <div class="filter__body" v-if="open">
      <div class="filter__group filter__group--header">
        <p class="text">Filter</p>
        <IconButton class="button--md" @click="open = false">
          <IconClose />
        </IconButton>
      </div>
      <div class="filter__body_content">
        <div class="filter__group" v-for="item in items" :key="item.group">
          <div class="filter__group_header">
            <p class="text">{{ item.group }}</p>
            <TogglePlusMinus :value="item.open" @click="item.open = !item.open" />
          </div>
          <div class="filter__group_body" :class="{ 'filter__group_body--open': item.open }">
            <Tag
              class="tag--capitalize"
              :class="{ 'tag--selected': i.selected }"
              v-for="(i, idx) in item.items"
              :key="`${i.title}-${idx}`"
              :value="i.title"
              @click="$emit('selectFilter', { key: item.key, filter: i })"
            />
          </div>
        </div>
      </div>
      <div class="filter__group filter__group--buttons">
        <BaseButton
          class="button--sm button--outline button--uppercase"
          text="clear"
          @click="$emit('clear')"
        />
        <BaseButton
          class="button--sm button--black button--uppercase"
          text="apply"
          @click="$emit('apply')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import IconButton from "@/components/buttons/IconButton.vue";
import TogglePlusMinus from "@/components/buttons/TogglePlusMinus.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconFilter from "@/components/icons/IconFilter.vue";
import IconClose from "@/components/icons/IconClose.vue";
import Tag from "@/components/Tag.vue";

export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    IconButton,
    TogglePlusMinus,
    BaseButton,
    IconFilter,
    Tag,
    IconClose,
  },
  data() {
    return {
      open: false,
    };
  },
  watch: {
    open(val) {
      this.$emit("open", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.filter {
  position: relative;
  .text {
    @include type($fw: 700);
    margin-right: 2rem;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
  }
  &__body {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -1px;
    right: -2px;
    transform: translate(0%, 0%);
    min-width: 45rem;
    max-width: 45rem;
    height: 57rem;
    background-color: $white;
    border-radius: 12px;
    box-shadow: 0 2px 16px rgba($color: $black, $alpha: 0.25);
    z-index: 3;
    &_content {
      overflow-y: auto;
    }
  }
  &__group {
    border-bottom: 0.5px solid $grey;
    padding: 0 0 0.3rem;
    &--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.4rem 1.3rem 1.3rem 2rem;
    }
    &--buttons {
      display: flex;
      border: none;
      padding: 2.4rem 2rem;
      margin: auto 0 0;
      .button {
        margin: 0 1.1rem;
      }
    }
    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.6rem 1.3rem 1.3rem 2rem;
    }
    &_body {
      display: flex;
      flex-wrap: wrap;
      margin: 0 1.3rem 0 1.5rem;
      max-height: 0;
      overflow-y: auto;
      transition: all 0.3s ease;
      &--open {
        max-height: 30rem;
      }
    }
  }
}
</style>
